<template>
  <div id="viewer" class="page">
    <div id="canvas-container"></div>
    <ul class="fixed text-red-900 flex flex-col">
      <router-link tag="li" to="/house/ENFJ">ENFJ</router-link>
      <router-link tag="li" to="/house/ENFP">ENFP</router-link>
      <router-link tag="li" to="/house/ENTJ">ENTJ</router-link>
      <router-link tag="li" to="/house/ENTP">ENTP</router-link>
      <router-link tag="li" to="/house/ESFJ">ESFJ</router-link>
      <router-link tag="li" to="/house/ESFP">ESFP</router-link>
      <router-link tag="li" to="/house/ESTJ">ESTJ</router-link>
      <router-link tag="li" to="/house/ESTP">ESTP</router-link>
      <router-link tag="li" to="/house/INFJ">INFJ</router-link>
      <router-link tag="li" to="/house/INFP">INFP</router-link>
      <router-link tag="li" to="/house/INTJ">INTJ</router-link>
      <router-link tag="li" to="/house/INTP">INTP</router-link>
      <router-link tag="li" to="/house/ISFJ">ISFJ</router-link>
      <router-link tag="li" to="/house/ISFP">ISFP</router-link>
      <router-link tag="li" to="/house/ISTJ">ISTJ</router-link>
      <router-link tag="li" to="/house/ISTP">ISTP</router-link>
    </ul>
    <!-- <div id="joystick"></div>
    <div id="joystick-overlay">
      <div id="up"></div>
      <div id="down"></div>
      <div id="left"></div>
      <div id="right"></div>
    </div>-->
  </div>
</template>

<style scoped>
#viewer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fdd;
  pointer-events: all;
}

#canvas-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
}

.router-link-active {
  color: black;
}

#joystick,
#joystick-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 250px;
}

#joystick-overlay {
  position: fixed;
  width: 250px;
  height: 250px;
  bottom: 0;
  left: 0;
}

#joystick-overlay,
#joystick-overlay > * {
  pointer-events: none;
}

#joystick-overlay > #up {
  position: absolute;
  top: 0px;
  left: 33.3%;
  width: 33.3%;
  height: 33.3%;
  background-color: red;
}
#joystick-overlay > #down {
  position: absolute;
  top: 66.6%;
  left: 33.3%;
  width: 33.3%;
  height: 33.3%;
  background-color: red;
}
#joystick-overlay > #left {
  position: absolute;
  top: 33.3%;
  left: 0%;
  width: 33.3%;
  height: 33.3%;
  background-color: red;
}
#joystick-overlay > #right {
  position: absolute;
  top: 33.3%;
  left: 66.6%;
  width: 33.3%;
  height: 33.3%;
  background-color: red;
}
</style>
<script>
import * as clay from 'claygl'
import ClayAdvancedRenderer from 'claygl-advanced-renderer'
import graphicConfig from '../assets/config/graphic.config.js'
import materialConfig from '../assets/config/material.config.js'
import PanControl from '../assets/PanControl.js'
import nipplejs from 'nipplejs'

export default {
  engine: {},
  name: 'View',
  props: {
    houseId: { type: String, default: '' }
  },
  data: function() {
    return {
      clayApp: {}
    }
  },
  watch: {},
  mounted() {
    // Create ClayGL application
    const appNS = {
      autoRender: false,
      devicePixelRatio: 1,
      graphic: {
        shadow: true,
        linear: true,
        tonemapping: true
      },
      init: function(app) {
        // Init Advanced Renderer
        app._advancedRenderer = new ClayAdvancedRenderer(
          app.renderer,
          app.scene,
          app.timeline,
          graphicConfig
        )

        // FOR DEBUG
        // window._advancedRenderer = app._advancedRenderer
        // window._app = app

        // this._originY = 0

        // Lights
        this._light = app.createDirectionalLight([3, -5, 4])

        // HDRI light
        this._ambient = app
          .createAmbientCubemapLight('/env/2k.hdr', 1, 0.3, 1, 32)
          .then(result => {
            console.log('AmbientCubemapLight Loaded', result)
            app._advancedRenderer.render()
            this._skybox = new clay.plugin.Skybox({
              scene: app.scene,
              environmentMap: result.environmentMap
            })
          })

        // Camera
        this._camera = app.createCamera([-8, 2, 8], [0, 1.7, 0])
        this._camera.aspect = app.renderer.getViewportAspect()
        this._camera.fov = hvfov(this._camera.aspect, { h: 60, v: 60 })
        app._advancedRenderer.render()

        // Materials
        // this._mat = {}
        // Object.entries(materialConfig.materials).forEach(([k, v]) => {
        //   console.log(`Adding material ${k}`)
        //   this._mat[k] = app.createMaterial(v)
        //   if (v.transparent) {
        //     this._mat[k].transparent = true
        //   }
        // })

        // Ground
        this._ground = app.createPlane(
          app.createMaterial({
            color: [0.7, 0.7, 0.7],
            diffuseMap: '/env/ground_4k.jpg',
            roughness: 0.7,
            metalness: 0,
            transparent: false
          })
        )
        this._ground.castShadow = false
        this._ground.position.set(0, 0, 0)
        this._ground.scale.set(30, 30, 1)
        this._ground.rotation.rotateY(Math.PI / 2)
        this._ground.rotation.rotateX(-Math.PI / 2)

        // Add avatar
        app
          .loadModel('/model/avatar/liam.glb', {
            waitTextureLoaded: true
          })
          .then(result => {
            result.rootNode.position.set(-3, 0, 3)
            result.rootNode.scale.set(0.9, 0.9, 0.9)
            result.rootNode.rotation.rotateY(-Math.PI / 2)
          })

        // Add house
        const filename =
          vm.houseId || vm.$route.query.houseId || vm.$route.params.houseId
        const modelPath = `/model/house/${filename}.glb`
        app
          .loadModel(modelPath)
          .then(result => console.log(result))
          .catch(error => console.error(`loading ${modelPath} failed`))

        this._rayPicking = new clay.picking.RayPicking({
          renderer: app.renderer,
          scene: app.scene,
          camera: this._camera
        })

        // Screen resize
        window.addEventListener('resize', () => {
          app.resize()
          this._camera.aspect = app.renderer.getViewportAspect()
          this._camera.fov = hvfov(this._camera.aspect, { h: 60, v: 60 })
          app._advancedRenderer.render()
        })
      },
      loop: function(app) {
        // !!! Execute render only when scene or camera changed
        // app._advancedRenderer.render();
      },
      methods: {
        // OrbitControl
        activateOrbitControl: function(app) {
          this._orbitControl = new clay.plugin.OrbitControl({
            target: this._camera,
            domElement: app.renderer.canvas,
            minAlpha: 0,
            maxAlpha: 60,
            minDistance: 10,
            maxDistance: 20,
            // autoRotate: true,
            // autoRotateAfterStill: 5,
            // autoRotateSpeed: 15,
            timeline: app.timeline
          }).on('update', () => {
            app._advancedRenderer.render()
          })
        },
        deactivateOrbitControl: function(app) {
          if (this._orbitControl) {
            this._orbitControl.dispose()
            delete this._orbitControl
          }
        },

        // FreeControl
        activateFreeControl: function(app) {
          this._freeControl = new clay.plugin.FreeControl({
            target: this._camera,
            domElement: app.renderer.canvas,
            timeline: app.timeline,
            verticalMoveLock: true
          }).on('change', () => {
            app._advancedRenderer.render()
          })
        },
        deactivateFreeControl: function(app) {
          if (this.freeControl) {
            this.freeControl.dispose()
            delete this.freeControl
          }
        },

        // Pan control
        activatePanControl: function(app) {
          console.warn('Activating Pan Control')
          this._panControl = new PanControl({
            target: this._camera,
            domElement: app.container,
            timeline: app.timeline,
            minAlpha: -45,
            maxAlpha: 45,
            minBeta: -45,
            maxBeta: 45
          }).on('update', () => {
            app._advancedRenderer.render()
          })
        },
        deactivatePanControl: function(app) {
          console.warn('Deactivating Pan Control')
          if (this._panControl) {
            this._panControl.dispose()
            delete this._panControl
          }
        },
        captureCanvas: function(app) {
          const canvas = app.renderer.canvas
          const renderer = app._advancedRenderer
          return new Promise(function(resolve, reject) {
            renderer._renderMain.afterRenderAll = function(
              renderer,
              scene,
              camera
            ) {
              console.warn('after advrenderer render', renderer, scene, camera)
              resolve(canvas.toDataURL('image/jpeg'))
              delete renderer._renderMain.afterRenderAll
            }
            renderer._renderMain.render()
          })
        }
      }
    }

    const vm = this
    const canvasContainer = this.$el.querySelector('#canvas-container')
    canvasContainer.addEventListener('touchforcechange', function(ev) {
      ev.preventDefault()
    })
    this.engine = clay.application.create(canvasContainer, appNS)
    this.engine.methods.activateFreeControl()

    /* nipple.js virtual joystick
     *
     */
    // const joystick_el = this.$el.querySelector('#joystick');
    // const manager = nipplejs.create({
    //   zone: joystick_el,
    //   mode: 'static',
    //   color: 'white',
    //   position: {top: '50%', left: '50%'}
    // });
    // manager.on('start end dir:up dir:down dir:left dir:right', function(evt,data) {
    //   console.log(evt.type, data)
    // })
  },
  beforeUnmount: function() {
    this.engine.methods.deactivateFreeControl()
    this.engine._advancedRenderer.dispose()
    this.engine.dispose()
  },
  methods: {
  }
}

/**
 * Calculate v-fov from h-fov and aspect-ratio
 * @param {number} hfov - horizontal fov of the view (degree)
 * @param {number} aspect - aspect ratio of the view (width/height)
 */
function hfov2vfov(hfov, aspect) {
  return (
    2 *
    Math.atan(Math.tan((hfov / 2) * (Math.PI / 180)) / aspect) *
    (180 / Math.PI)
  )
}

/**
 * Calculate v-fov to ensure minimum view angles
 * in both horizontal & vertical directions
 * @param {number} aspect - aspect ratio of the view (width/height)
 * @param {object} options - { h: number, v: number }
 */
function hvfov(aspect, options) {
  options = options || { h: 60, v: 60 }
  const fovAspect =
    Math.tan((options.h / 2) * (Math.PI / 180)) /
    Math.tan((options.v / 2) * (Math.PI / 180))
  let fov
  if (aspect > fovAspect) {
    fov = options.v
  } else {
    fov = hfov2vfov(options.h, aspect)
  }
  return fov
}
</script>

