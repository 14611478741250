<template>
  <div class="bg-anim"></div>
  <div class="bg-vignette"></div>

  <div class="flex flex-col justify-center content-center h-screen text-2xl">
    <div v-if="loggedIn" class="">
      <div v-if="haveHouse">
        Here is your pretty house. Wanna see it?
        <div>
          <a href="/view"><button>Look around your house</button></a>
        </div>
      </div>
      <div v-else>
        12가지 질문을 통해 당신의 집을 지금 만들어볼 수 있습니다.
        <div>
          <a href="/create"><button>Start</button></a>
        </div>
      </div>
    </div>
    <div v-else>
      당신의 집은 아직 여기에 없군요...<br />
      당신의 성격에 꼭 맞는 집을 만들어 볼까요?
      <div>
        <router-link to="/create">
          <button class="pt-8 round-sm border-0">
            <b>시작하기</b>
          </button>
        </router-link>
      </div>
    </div>
  </div>

  <div class="text-red-500 fixed w-screen h-screen">
    <button v-if="!loggedIn" @click="_login">로그인</button>
    <button v-if="loggedIn">로그인됨</button>
    <button v-if="loggedIn && !haveHouse" @click="_createHouse">집없음</button>
    <button v-if="loggedIn && haveHouse" @click="_destroyHouse">집있음</button>
  </div>



</template>

<script>
export default {
  name: 'AppFront',
  data() {
    return {
      loggedIn: false,
      haveHouse: false
    }
  },
  methods: {
    _createHouse() {
      console.log('House created')
      this.haveHouse = true
    },
    _destroyHouse() {
      console.log('House destroyed')
    }
  }
}
</script>

<style>
@keyframes bg-scroll {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -200vw, 0);
  }
}

.bg-anim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 400vw;
  background-image: url(../assets/tile-e2-4x8.jpg);
  background-position: 0 0;
  background-size: 100%;
  background-repeat: repeat-x repeat-y;
  animation: bg-scroll 30s linear infinite;
  overflow: visible;
  z-index: -2;
}

.bg-vignette {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  background: radial-gradient(circle, transparent 20%, black 100%);
  z-index: -1;
}
</style>
