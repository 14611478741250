// Check out defaultGraphicConfig.js in claygl-advanved-renderer for documentation
export default {
  shadow: {
    enable: true,
    kernelSize: 4,
    blurSize: 2
  },
  temporalSuperSampling: {
    dynamic: true,
    enable: 'auto'
  },
  postEffect: {
    enable: true,
    bloom: {
      enable: true,
      intensity: 0.1
    },
    depthOfField: {
      enable: false,
      focalDistance: 5,
      focalRange: 1,
      blurRadius: 5,
      fstop: 2.8,
      quality: 'medium'
    },
    screenSpaceAmbientOcclusion: {
      enable: true,
      radius: 3,
      quality: 'medium',
      intensity: 0.75,
      temporalFilter: true
    },
    // screenSpaceReflection: {
    //   enable: true,
    //   physical: false,
    //   quality: 'medium',
    //   maxRoughness: 0.5
    // },
    colorCorrection: {
      enable: true,
      exposure: 0,
      brightness: 0,
      contrast: 1,
      saturation: 1,
      lookupTexture: ''
    },
    FXAA: {
      enable: false
    }
  }
}
