/* eslint-disable */
import { Vector2, plugin, core, Quaternion } from 'claygl'

var MOUSE_BUTTON_KEY_MAP = {
  left: 0,
  middle: 1,
  right: 2
}

function convertToArray(val) {
  if (!Array.isArray(val)) {
    val = [val, val]
  }
  return val
}

var PanControl = core.Base.extend(
  function() {
    return {
      timeline: null,
      domElement: null,
      target: null,
      minAlpha: -90,
      maxAlpha: 90,
      minBeta: -Infinity,
      maxBeta: Infinity,
      rotateMouseButton: 'left',
      _mode: 'rotate',
      damping: 0.8,
      rotateSensitivity: 1,
      _needsUpdate: false,
      _rotating: false,
      _phi: 0,
      _theta: 0,
      _mouseX: 0,
      _mouseY: 0,
      _rotateVelocity: new Vector2(),
      _animators: [],
      _gestureMgr: new plugin.GestureMgr(),
      _originalRotation: Quaternion.identity
    }
  },
  function() {
    this._mouseDownHandler = this._mouseDownHandler.bind(this)
    this._mouseMoveHandler = this._mouseMoveHandler.bind(this)
    this._mouseUpHandler = this._mouseUpHandler.bind(this)
    this.init()
  },
  {
    init: function() {
      var dom = this.domElement
      core.vendor.addEventListener(dom, 'touchstart', this._mouseDownHandler)
      core.vendor.addEventListener(dom, 'mousedown', this._mouseDownHandler)

      if (this.timeline) {
        this.timeline.on('frame', this.update, this)
      }
      if (this.target) {
        // this.decomposeTransform()
        this._originalRotation = this.target.rotation.clone()
      }
    },

    dispose: function() {
      var dom = this.domElement

      core.vendor.removeEventListener(dom, 'touchstart', this._mouseDownHandler)
      core.vendor.removeEventListener(dom, 'touchmove', this._mouseMoveHandler)
      core.vendor.removeEventListener(dom, 'touchend', this._mouseUpHandler)

      core.vendor.removeEventListener(dom, 'mousedown', this._mouseDownHandler)
      core.vendor.removeEventListener(dom, 'mousemove', this._mouseMoveHandler)
      core.vendor.removeEventListener(dom, 'mouseup', this._mouseUpHandler)
      core.vendor.removeEventListener(dom, 'mouseout', this._mouseUpHandler)

      if (this.timeline) {
        this.timeline.off('frame', this.update)
      }
      this.stopAllAnimation()
    },

    getAlpha: function() {
      return (this._theta / Math.PI) * 180
    },
    getBeta: function() {
      return (-this._phi / Math.PI) * 180
    },
    setAlpha: function(alpha) {
      alpha = Math.max(Math.min(this.maxAlpha, alpha), this.minAlpha)
      this._theta = (alpha / 180) * Math.PI
      this._needsUpdate = true
    },
    setBeta: function(beta) {
      beta = Math.max(Math.min(this.maxBeta, beta), this.minBeta)
      this._phi = (-beta / 180) * Math.PI
      this._needsUpdate = true
    },
    setOption: function(opts) {
      opts = opts || {}
      ;[
        'damping',
        'minAlpha',
        'maxAlpha',
        'minBeta',
        'maxBeta',
        'rotateSensitivity'
      ].forEach(function(key) {
        if (opts[key] != null) {
          this[key] = opts[key]
        }
      }, this)

      if (opts.alpha != null) {
        this.setAlpha(opts.alpha)
      }
      if (opts.beta != null) {
        this.setBeta(opts.beta)
      }
      if (this.target) {
        this._updateTransform()
        this.target.update()
      }
    },

    animateTo: function(opts) {
      var self = this

      var obj = {}
      var target = {}
      var timeline = this.timeline
      if (!timeline) {
        return
      }

      return this._addAnimator(
        timeline
          .animate(obj)
          .when(opts.duration || 1000, target)
          .during(function() {
            if (obj.alpha != null) {
              self.setAlpha(obj.alpha)
            }
            if (obj.beta != null) {
              self.setBeta(obj.beta)
            }
            self._needsUpdate = true
          })
          .done(opts.done)
      ).start(opts.easing || 'linear')
    },

    stopAllAnimation: function() {
      for (var i = 0; i < this._animators.length; i++) {
        this._animators[i].stop()
      }
      this._animators.length = 0
    },

    _isAnimating: function() {
      return this._animators.length > 0
    },

    update: function(deltaTime) {
      deltaTime = deltaTime || 16

      if (this._rotateVelocity.len() > 0) {
        this._needsUpdate = true
      }

      if (!this._needsUpdate) {
        return
      }

      // Fixed deltaTime
      this._updateRotate(Math.min(deltaTime, 50))
      this._updateTransform()
      this.target.update()
      this.trigger('update')
      this._needsUpdate = false
    },

    _updateRotate: function(deltaTime) {
      var velocity = this._rotateVelocity
      this._phi = (velocity.y * deltaTime) / 20 + this._phi
      this._theta = (velocity.x * deltaTime) / 20 + this._theta
      this.setAlpha(this.getAlpha())
      this.setBeta(this.getBeta())
      this._vectorDamping(velocity, this.damping)
    },

    _updateTransform: function() {
      var camera = this.target

      camera.rotation = this._originalRotation.clone()
      camera.rotation.rotateY(this._phi).rotateX(this._theta)

      console.warn(`_updateTransform`, this.target.rotation)
    },

    _vectorDamping: function(v, damping) {
      var speed = v.len()
      speed = speed * damping
      if (speed < 1e-4) {
        speed = 0
      }
      v.normalize().scale(speed)
    },

    // decomposeTransform: function () {
    //   if (!this.target) {
    //     return
    //   }

    //   this.target.updateWorldTransform()

    //   var forward = this.target.worldTransform.z
    //   var alpha = Math.asin(forward.y)
    //   var beta = Math.atan2(forward.x, forward.z)

    //   this._theta = alpha
    //   this._phi = -beta

    //   this.setBeta(this.getBeta())
    //   this.setAlpha(this.getAlpha())
    // },

    _mouseDownHandler: function(e) {
      if (this._isAnimating()) {
        return
      }
      var x = e.clientX
      var y = e.clientY
      // Touch
      if (e.targetTouches) {
        var touch = e.targetTouches[0]
        x = touch.clientX
        y = touch.clientY

        this._mode = 'rotate'

        this._processGesture(e, 'start')
      } else {
        if (e.button === MOUSE_BUTTON_KEY_MAP[this.rotateMouseButton]) {
          this._mode = 'rotate'
        } else {
          this._mode = null
        }
      }

      var dom = this.domElement
      core.vendor.addEventListener(dom, 'touchmove', this._mouseMoveHandler)
      core.vendor.addEventListener(dom, 'touchend', this._mouseUpHandler)

      core.vendor.addEventListener(dom, 'mousemove', this._mouseMoveHandler)
      core.vendor.addEventListener(dom, 'mouseup', this._mouseUpHandler)
      core.vendor.addEventListener(dom, 'mouseout', this._mouseUpHandler)

      // Reset rotate velocity
      this._rotateVelocity.set(0, 0)
      this._rotating = false

      this._mouseX = x
      this._mouseY = y
    },

    _mouseMoveHandler: function(e) {
      console.warn(`pan: _mouseMoveHnadler`)

      if (this._isAnimating()) {
        return
      }
      var x = e.clientX
      var y = e.clientY

      var haveGesture
      // Touch
      if (e.targetTouches) {
        var touch = e.targetTouches[0]
        x = touch.clientX
        y = touch.clientY

        haveGesture = this._processGesture(e, 'change')
      }

      var rotateSensitivity = convertToArray(this.rotateSensitivity)

      if (!haveGesture) {
        if (this._mode === 'rotate') {
          this._rotateVelocity.y +=
            ((x - this._mouseX) / this.domElement.clientWidth) *
            2 *
            rotateSensitivity[0]
          this._rotateVelocity.x +=
            ((y - this._mouseY) / this.domElement.clientHeight) *
            2 *
            rotateSensitivity[1]
        }
      }

      this._mouseX = x
      this._mouseY = y

      e.preventDefault && e.preventDefault()
    },

    _mouseUpHandler: function(event) {
      var dom = this.domElement
      core.vendor.removeEventListener(dom, 'touchmove', this._mouseMoveHandler)
      core.vendor.removeEventListener(dom, 'touchend', this._mouseUpHandler)
      core.vendor.removeEventListener(dom, 'mousemove', this._mouseMoveHandler)
      core.vendor.removeEventListener(dom, 'mouseup', this._mouseUpHandler)
      core.vendor.removeEventListener(dom, 'mouseout', this._mouseUpHandler)
      this._processGesture(event, 'end')
    },

    _addAnimator: function(animator) {
      var animators = this._animators
      animators.push(animator)
      animator.done(function() {
        var idx = animators.indexOf(animator)
        if (idx >= 0) {
          animators.splice(idx, 1)
        }
      })
      return animator
    },

    _processGesture: function(event, stage) {
      var gestureMgr = this._gestureMgr
      stage === 'start' && gestureMgr.clear()
      var gestureInfo = gestureMgr.recognize(event, null, this.domElement)
      stage === 'end' && gestureMgr.clear()
      return gestureInfo
    }
  }
)

Object.defineProperty(PanControl.prototype, 'target', {
  get: function() {
    return this._target
  },
  set: function(val) {
    this._target = val
    if (val?.rotation) {
      this._originalRotation = val.rotation.clone()
    }
    // this.decomposeTransform()
  }
})

export default PanControl
