<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<style>
/* @import './assets/preflight.css'; */
/* @import '../node_modules/modern-normalize/modern-normalize.css'; */
/* @import '../node_modules/normalize.css/normalize.css'; */

/* Set root text size */
/* Set root text size to proportional with screen width.
   - reference screen width: 320px
   - reference font size: 16px;
   */
/* * {
  touch-action: manipulation;
} */

html {
  font-size:calc(min(4vw,0.04*640px))
}
  /* font-size:calc(min(5vw,5vh)); */
  /* font-size:4vmin; */
  /* line-height: 1; */
  /* font-size: 1.2em; */
  /* line-height: 120%; */


/* Disable iOS Safari rubber band bouncing */
html, body { 
  position: fixed;
  overflow: hidden;
}

/* iOS Mobile Safari 100vh bug workaround */

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}




#app {
  font-family: '777Balsamtint';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #eee;

}
.apexcharts-yaxis {
display:none;
}
/* #nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
} */

#nav a.router-link-exact-active {
  color: #42b983;
}

@font-face {
    font-family: 'Bazzi';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/Bazzi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'CookieRun-Regular';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/CookieRun-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: '777Balsamtint';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_nine_@1.1/777Balsamtint.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: '777Flowerbear';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_nine_@1.1/777Flowerbear.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.font-cute {
  /* font-family: 'Bazzi'; */
  /* font-family: 'CookieRun-Regular'; */
  font-family: '777Balsamtint';
  /* font-family: '777Flowerbear'; */
}
.font-2x {
  font-size:1.5rem;
  line-height: 140%;
}
.border-0 {
 border-width:0px;
}
.round-sm {
  border-radius: 0.5em;
}

.px-8 {
 padding-left: 1rem;
 padding-right: 1rem;
}

.py-2 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
</style>
