<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <AppFront/>
  </div>
</template>

<script>
// @ is an alias to /src
import AppFront from '@/components/AppFront.vue'

export default {
  name: 'Home',
  components: {
    AppFront
  }
}
</script>

<style scoped>
.home {
  width:100%;
  height:100%;
}
</style>
